@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  text-rendering: optimizeSpeed;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

input,
button,
textarea,
select {
  font: inherit;
}

img {
  max-width: 100%;
  display: block;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

a {
  text-decoration: none;
  display: inline-block;
}
