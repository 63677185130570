.AboutUs {
  h1 {
    font-size: 2.75rem;
    font-weight: 500;
    color: var(--green);
  }

  h2 {
    font-size: 1.375rem;
  }

  .AboutUsImg {
    height: 31.25rem;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
