.about {
  .about-text {
    color: var(--white);
    font-weight: 700;

    h1 {
      padding-top: 30%;
    }
  }
}

@media screen and (min-width: 1024px) {
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
}
@media screen and (max-width: 767px) {
  .about {
    .about-text {
      height: 12rem;
      h1 {
        padding-top: 20%;
      }
    }
  }
}
