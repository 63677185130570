.wrapper {
  background-color: var(--offWhite);

  .wrapperCard {
    color: black;
    border-radius: 0.5rem;

    h1,
    svg {
      font-size: 1.5rem;
    }

    svg {
      fill: var(--green);
    }

    &:hover {
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);
      transition: 0.4s ease-in-out;

      h1 {
        color: var(--green);
        transition: 0.4s ease-in-out;
      }
    }
  }
}
