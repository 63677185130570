.learnMore {
  padding: 0.5rem 1rem;
  background: var(--green);
  color: var(--white);
  font-size: 1rem;
  border-radius: 2rem;
  font-weight: 500;

  &:hover {
    background: var(--black-soft);
    transition: 0.4s ease-in-out;
  }
}

.tagBtn {
  padding: 0.5rem 1rem;
  color: var(--black-soft);
  font-size: 1rem;
  border: 0.125rem solid var(--green);
  margin: 0.5rem 0.5rem;

  &:hover {
    transition: 0.4s ease-in-out;
    opacity: 0.8;
  }
}
