.navbar {
  background-color: var(--white);
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;

  .logo {
    width: 209.6px;
    height: 91px;
  }

  .nav-link {
    color: var(--green);
    font-weight: 400;

    &:focus,
    &:focus-visible {
      color: var(--offWhite);
    }

    &.activePage {
      color: var(--green);
      font-weight: 600;
    }
  }
}
