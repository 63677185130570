:root {
  --black: black;
  --white: white;
  --offWhite: #f5f5f7;
  --black-soft: #2b4149;
  --black-dark: #232f3e;
  --skyBlue-light: #6a98bb;
  --orange-dark: #bf4800;
  --light-gray: #777777;
  --green: #97fb98;
}
