.hr {
  width: 8rem;
  height: 0.324rem;
  background-color: var(--green);
  margin: auto;
  opacity: 1;
}

.readMore {
  color: var(--green);
  font-weight: 700;
}

.pageHero {
  .img {
    img {
      height: 30rem;
    }
  }
  .content {
    top: 40%;
    width: inherit;

    h1 {
      font-size: 3.25rem;
      font-weight: 700;
      text-align: center;
      color: var(--green) !important;
    }
  }
}

.learnMoreCardImage {
  height: 30rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  -webkit-transition: background-color 0.5s ease-out;
  transition: background-color 0.5s ease-out;
}

.learnMoreCard {
  height: 30rem;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  background-color: var(--offWhite);
  padding: 0 1rem;

  svg {
    font-size: 5rem;
    color: var(--green);
    margin: 0 auto;
  }
}

.UnderContraction {
  padding-top: 3rem;
  svg {
    font-size: 10rem;
    fill: var(--green);
  }

  h1 {
    font-size: 3rem;
    font-weight: 700;
    padding-top: 2rem;
  }
}

@media screen and (min-width: 1024px) {
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
}
@media screen and (max-width: 767px) {
  .pageHero {
    .img {
      img {
        height: 20rem;
      }
    }
  }
}
