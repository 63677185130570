.WhatWeOffer {
  background-color: var(--offWhite);

  .headLine {
    font-size: 1.625rem;
    color: var(--green);
    font-weight: 700;

    &:hover {
      text-decoration: underline;
      transition: 0.4s ease-in-out;
    }
  }

  img {
    width: 100%;
    height: 25rem;
    // height: fit-content;
    border-top-right-radius: 6.25rem;
    border-top-left-radius: 6.25rem;
    border-bottom-right-radius: 6.25rem;
  }
}

@media screen and (min-width: 1024px) {
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
}
@media screen and (max-width: 767px) {
  .WhatWeOffer {
    img {
      border-top-right-radius: 3.25rem;
      border-top-left-radius: 3.25rem;
      border-bottom-right-radius: 3.25rem;
    }
  }
}
