@media screen and (min-width: 1024px) {
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
}
@media screen and (max-width: 767px) {
  .pt-xs-5 {
    padding-top: 1.5rem;
  }

  .pb-xs-5 {
    padding-bottom: 1.5rem;
  }

  .flex-xs-column {
    flex-direction: column;
    text-align: center;
  }

  .d-xs-none {
    display: none;
  }
}
