footer {
  svg {
    font-size: 1.5rem;
  }
}

.FooterTop {
  background-color: var(--offWhite);

  h1 {
    font-size: 2.75rem;
  }

  a {
    color: var(--green);

    &:hover {
      text-decoration: underline;
    }
  }

  .contactInfo {
    svg {
      margin-right: 0.7rem;
    }
  }

  .socialIcons {
    a {
      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.FooterMiddle {
  background-color: var(--white);

  h1 {
    font-size: 1.625rem;
    font-weight: 700;
  }

  img {
    height: 6rem;
    margin: 0 auto;
  }

  p {
    text-align: justify;
    color: var(--black-dark);
  }

  .mini-logo {
    img {
      width: 7rem;
      height: 7rem;
    }
  }

  .recentPostContent {
    .img {
      margin-right: 1rem;

      svg {
        font-size: 5rem;
        background-color: var(--white);
        padding: 1rem;
        border-radius: 50%;
        color: var(--green);
      }
    }

    .date {
      color: var(--light-gray);
      font-weight: 500;
    }
  }

  .contactForm {
    .form-floating {
      .form-control {
        box-shadow: 0 0 0 0.25rem rgba(13, 109, 253, 0) !important;
      }
      &:focus {
        border-color: transparent !important;
      }

      * {
        background-color: transparent;
      }
    }
    input {
      background-color: var(--offWhite) !important;
      color: var(--green);
      outline: none;
      height: 2rem;
      // background-color: transparent;
    }

    label {
      color: var(--green);
      background-color: transparent !important;
    }

    textarea {
      background-color: var(--offWhite) !important;
      color: var(--green);
      outline: none;
    }
  }

  .button {
    width: 100%;
    background-color: var(--green);
    text-align: center;
    color: var(--white);
    padding: 0.5rem 0;
    font-size: 1rem;
    font-weight: 500;

    &:hover {
      opacity: 0.9;
      transition: 0.3s ease;
    }
  }
}

.FooterBottom {
  background-color: var(--offWhite);

  svg {
    &:hover {
      color: var(--green);
    }
  }

  a {
    color: var(--black-dark);

    &:hover {
      color: var(--green);
    }
  }
}

@media screen and (min-width: 1024px) {
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
}
@media screen and (max-width: 767px) {
  .FooterBottom {
    text-align: center;

    div & :nth-child(2) {
      padding: 0.5rem 0;
    }
  }
}
