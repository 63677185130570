.contact {
  .contactDetails {
    h1 {
      font-size: 2.75rem;
      font-weight: 500;
      color: var(--green);
    }

    svg {
      font-size: 8rem;
      color: var(--green);
    }

    h2 {
      font-size: 1.375rem;
    }

    .contactInfo {
      p {
        text-align: justify;
      }
    }
  }
}
